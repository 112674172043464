<template>
  <ak-container card :bodyStyle="{padding: 0}" class="ak-card-table">
    <a-card style="width: 96%; margin: 10px auto;margin-bottom: 20px">
      <a-form-model ref="form" class="ak-query-filter" :model="queryFilters" :colon="false">
        <a-row :gutter="24">
        <a-col v-bind="$wrapperThreeCol">
          <a-form-model-item label="手机号" prop="phoneNumber">
            <a-input v-model="queryFilters.phone" placeholder="请输入" />
          </a-form-model-item>
        </a-col>

        <a-col v-bind="$wrapperThreeCol">
          <a-form-model-item label="名称" prop="userName">
            <a-input v-model="queryFilters.shopName" placeholder="请输入" />
          </a-form-model-item>
        </a-col>

        <a-col v-bind="$wrapperThreeCol">
          <a-form-model-item label="昵称" prop="nickName">
            <a-input v-model="queryFilters.nickName" placeholder="请输入" />
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row :gutter="24">
        <a-col v-bind="$wrapperThreeCol">
          <a-form-model-item label="状态" prop="state">
            <ak-select :loadData="$const.STATUS" allowClear v-model="queryFilters.state" placeholder="请选择" />
          </a-form-model-item>
        </a-col>

        <a-col v-bind="$wrapperCol">
          <a-form-model-item label="入驻时间" prop="date">
            <a-range-picker v-model="queryFilters.date" show-time class="W100" :placeholder="['请选择', '请选择']" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD"/>
          </a-form-model-item>
        </a-col>

        <a-col v-bind="$wrapperCol" class="action-btn-main">
          <a-button type="primary" class="ML12" @click="search">查询</a-button>
          <a-button class="ML12" @click="onResetForm">重置</a-button>
        </a-col>
      </a-row>
    </a-form-model>
    </a-card>

    <a-table :pagination='pagination'
              :columns="columns"
              :loading="loading"
              :data-source="tableList"
              :scroll="{ x: 100 }"
              :rowKey="(record) => record.id"
              @change="handleTableChange" style="width:96%;margin: 10px auto;">
        <template #logo="text">
          <img v-if="text!==''" :src="`${$const.OssImagePrefix}/${text}`" class="headImage" alt="">
        </template>
        <!-- 绑定用户 -->
        <template #personalResp="text">
          <a-row type="flex" align="middle">
            <a-col :span="12">
              <img :src="text.headImage" class="headImage" alt="">
            </a-col>
            <a-col :span="12" style="text-align: right;">
              {{ text.name }}<br>{{ text.phone }}
            </a-col>
          </a-row>
        </template>
       <!-- 状态 -->
       <template #state="text">
        <span v-if="text === 1">正常</span>
        <span v-else class="redColor">禁用</span>
      </template>

      <!-- 操作 -->
      <template #action="text, record" style="height: 60px;">
        <router-link :to="`/user-manage/createrManage/createrDetail?id=${record.userId}&type=creater`">
          详情
        </router-link>
        &nbsp;&nbsp;
        <a @click="audit(record)" v-if="record.state === 1">禁用</a>
        <a @click="audit(record)" v-else>正常</a>
        <!-- <a-divider type="vertical" /> -->
        &nbsp;&nbsp;
        <router-link :to="`/user-manage/enterprise-audit/index`">
          企业认证
        </router-link>
        &nbsp;&nbsp;
        <router-link :to="{path:`/user-manage/createrManage/batchFnas`, query: record}">
          刷粉丝
        </router-link>
      </template>
    </a-table>

    <!-- 禁用/正常 弹窗-->
    <StatusModal
      :visible ="modal.statusVisible"
      :type ="type"
      @confirmStatus ="confirmStatus"
      @cancelStatus ="modal.statusVisible = false"
    />
  </ak-container>
</template>

<script>
import * as Business from '@/api/user/business'
import StatusModal from '@/components/StatusModal'

const columns = [{
  title: 'LOGO',
  dataIndex: 'logo',
  scopedSlots: { customRender: 'logo' },
  width: 120
}, {
  title: '名称',
  dataIndex: 'shopName',
  width: 120
}, {
  title: '绑定用户',
  dataIndex: 'personalResp',
  scopedSlots: { customRender: 'personalResp' },
  align: 'center',
  width: 200
}, {
  title: '状态',
  dataIndex: 'state',
  scopedSlots: { customRender: 'state' },
  align: 'center',
  width: 80
}, {
  title: '描述',
  dataIndex: 'describe',
  width: 200
}, {
  title: '粉丝',
  dataIndex: 'fansNum',
  width: 80
}, {
  title: '关注',
  dataIndex: 'follow',
  width: 80
}, {
  title: '获赞与收藏',
  dataIndex: 'favorite',
  width: 180
}, {
  title: '最后IP属地',
  dataIndex: 'ipLocation',
  width: 120
}, {
  title: '最后登录时间',
  dataIndex: 'lastLoginTime',
  defaultSortOrder: 'descend', // 默认上到下为由大到小的顺序
  sorter: (a, b) => { return a.lastLoginTime > b.lastLoginTime ? 1 : -1 },
  width: 200
}, {
  title: '入驻时间',
  dataIndex: 'createdTime',
  defaultSortOrder: 'descend', // 默认上到下为由大到小的顺序
  sorter: (a, b) => { return a.createdTime > b.createdTime ? 1 : -1 },
  width: 200
}, {
  title: '操作',
  dataIndex: 'action',
  fixed: 'right',
  scopedSlots: { customRender: 'action' },
  width: '220px'
}]

export default {
  components: { StatusModal },
  data () {
    return {
      loading: false,
      pagination: {
        total: 0,
        pageSize: 10, // 每页中显示10条数据
        showSizeChanger: true,
        pageSizeOptions: ["10", "20", "50", "100"], // 每页中显示的数据
        showTotal: total => `共有 ${total} 条数据`, // 分页中显示总的数据
        current: 1
      },
      advanced: false,
      // 查询参数
      queryFilters: {
        page: 1,
        pageSize: 10,
        date: [],
        businessType: 1, // 个人，1，创作者平台，2独立店，3商家
      },
      columns,
      tableList: [],
      modal: {
        statusVisible: false
      },
      userId: null,
      loadingStatus: false,
      type: '',
    }
  },
  created(){
    this.queryData()
  },
  methods: {
    // 表格分页条件、筛选条件、排序条件发生变化后刷新表格数据
    handleTableChange(pagination, filters, sorter) {
      console.log(pagination)
      this.sortedInfo = sorter;
      this.pagination.current = pagination.current;
       this.pagination.pageSize = pagination.pageSize;
      this.queryFilters.page = pagination.current;
      this.queryFilters.pageSize = pagination.pageSize;
      this.queryData()
    },
    // 加载数据方法
    queryData () {
      this.loading = true
      let _queryFilters = {}
      if (this.queryFilters.date.length >= 1) {
        this.queryFilters.createdTimeStart = this.queryFilters.date[0]
        this.queryFilters.createdTimeEnd = this.queryFilters.date[1]
        _queryFilters = this.$utils.filterObjKeys(this.queryFilters, ['date'])
      } else {
        _queryFilters = this.$utils.filterObjKeys(this.queryFilters, ['createdTimeStart', 'createdTimeEnd', 'date'])
      }
      Business.queryBusiness({..._queryFilters})
        .then((res) => {
          if (res.code === 0){
            this.tableList = res.data.list
            this.pagination.total = res.data.total
          }
          this.loading = false
        })
        .catch(e => {
          this.message = e.message || '查询错误'
        })
        .finally(() => {
          this.loading = false
        })
    },
    search(){
      this.pagination.current=1;
      this.queryFilters.page = 1;
      this.queryData();
    },
    // 重置
    onResetForm () {
      this.$refs.form.resetFields()
      this.queryFilters = this.$options.data.call(this).queryFilters
      this.queryData()
    },
    audit(row) {
      this.type = row.state.toString()
      this.userId = row.userId
      this.modal.statusVisible = true
    },
    confirmStatus() {
      const param = {
        userId: this.userId,
        state: this.type === '1' ? 0 : 1
      }
      this.loadingStatus = true
      Business.updateStateBusiness(param)
        .then((res) => {
          console.log('res', res)
          const { code } = res
          console.log('code', code)
          this.loadingStatus = false
          if (code === 0) {
            this.$message.success('状态修改成功')
            this.modal.statusVisible = false
            this.queryData()
          }
        })
        .catch(e => {
          this.message = e.message || '查询错误'
        })
        .finally(() => {
          this.loading = false
        })
    },
  }
}
</script>
<style lang="less" scoped>
/deep/ .ant-card-body{
  padding: 18px;
}

/deep/ .ant-col-xl-8 {
  padding-left: 0 !important;
}
</style>
